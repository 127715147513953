.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color:  #282828 ;
  min-height: 84vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-projects {
  background-color:  #282828 ;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: whitesmoke;
}

.App-skills {
  background-color:  #282828 ;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: whitesmoke;
}

.App-footer {
  background-color:  #282828 ;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: whitesmoke;
}

.App-link {
  color: #61dafb;
}

.padding {
  padding-left: 50px;
  padding-right: 50px;
}

